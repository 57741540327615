import { PayloadAction, createSlice } from '@reduxjs/toolkit'

const optimizeRoutesSmartPlannerSlice = createSlice({
  name: 'optimizeRoutes',
  initialState: {} as any,
  reducers: {
    updateOptimizeRoutesResponse: (state, action: PayloadAction<any>) => {
      if (!action.payload) {
        return {}
      }
      return {
        ...state,
        ...action.payload,
      }
    },
    updateOptimizeRoutesStatus: (state, action: PayloadAction<any>) => {
      state.status = action.payload
    },
    updateOptimizeLocations: (state, action: PayloadAction<any>) => {
      state.optimizedLocations = action.payload
    },
    updateOptimizeErrors: (state, action: PayloadAction<any>) => {
      state.bundle_errors = action.payload
    },
  },
  extraReducers: () => {},
})

export const optimizeRoutesSmartPlannerCreator = optimizeRoutesSmartPlannerSlice.actions

export default optimizeRoutesSmartPlannerSlice

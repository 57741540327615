import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import $ from 'jquery'
// UTILS
import {
  currentServiceType as currentServiceTypeUtils,
  currentVehicleType as currentVehicleTypeUtils,
  vehicleTypes as vehicleTypesUtils,
  getVehicleType,
  getServiceType,
  getQuickChoice,
  minuMumBufferMinutes,
  verifyTimeType,
  isCustomerEditBooking,
} from 'utils/new_booking/common'
import { Utils } from 'utils/Utils'
import { LocationPopover } from 'utils/LocationUtil'
import I18n from 'i18n/i18n'
import CommonUtils from '../../../utils/common'

// ACTIONS
import * as timeTypeActionCreators from 'store/actions/new_booking/timeTypeActionCreators'
import * as bookingActionCreators from 'store/actions/common/bookingActionCreators'
import * as serviceTypeActionCreators from 'store/actions/new_booking/serviceTypeActionCreators'
import * as prevTimeTypeActionCreators from 'store/actions/new_booking/prevTimeTypeActionCreators'
import * as calendarBookingActionCreators from 'store/actions/common/calendarActionCreators'
// COMPONENTS
import BookingCalendar from '../../common/BookingCalendar'
// CONSTANTS
import {
  NOW,
  IMMEDIATE,
  SCHEDULE,
  FULL_DAY,
  QUICK_CHOICE,
  LONG_HAUL,
  ONE_MIN_TO_MS,
  FAV_DRIVER_ID,
} from 'constants/bookingConstants'
import {
  ID_NEW_SERVICE_TYPE,
  ID_NEW_VEHICLE_TYPE,
} from 'constants/newBookingConstants'
import {
  FULL_DAY_DARK_GRAY_ICON,
  FULL_DAY_WHITE_ICON,
  LONG_HAUL_GRAY_ICON,
  LONG_HAUL_WHITE_ICON,
  ICON_FAV_DRIVER_ADDED,
} from 'constants/imageConstants'
import { SHOW_DATE_PICKER_BY_BOOKING } from 'constants/common/popupConstants'
import { outOfServiceStatusActionsCreator } from 'store/toolkit/outOfServiceStatus/outOfServiceStatus.reducer'
import { quickChoiceActionsCreator } from 'store/toolkit/newBooking/quickChoice/quickChoice.reducer'
import vehicleTypeActionCreators from 'store/actions/new_booking/vehicleTypeActionCreators'
import * as stepActionCreators from 'store/actions/new_booking/stepActionCreators'
import { autoSwitchLongHaulActionsCreator } from 'store/toolkit/newBooking/autoSwitchLongHaul.reducer'
import { showDatePickerActionsCreator } from 'store/toolkit/newBooking/showDatePicker.reducer'
import { timeTypeUIActionsCreator } from 'store/toolkit/newBooking/timeTypeUI.reducer'
import { showQuickChoiceActionsCreator } from 'store/toolkit/newBooking/quickChoice/showQuickChoice.reducer'
import { currentPopupIDActionsCreator } from 'store/toolkit/currentPopupID/currentPopupID.reducer'
import { infoAssignDriverPopupActionsCreator } from 'store/toolkit/infoAssignDriverPopup/infoAssignDriverPopup.reducer'
import { pickupTimeActionsCreator } from 'store/toolkit/newBooking/pickupTime.reducer'
import { isMarketingPage } from 'utils/booking/common'
// ASSETS

const mapStateToProps = state => ({
  assignedDriver: state.assignedDriver,
  currentVehicleType: currentVehicleTypeUtils(state),
  currentServiceType: currentServiceTypeUtils(state),
  extraInfos: state.extraInfos,
  pickupTime: state.pickupTime,
  timeType: state.timeType,
  timeTypeUI: state.timeTypeUI,
  bookAgainDetails: state.bookAgainDetails,
  quickChoiceID: state.quickChoiceID,
  quickChoiceName: state.quickChoiceName,
  showDatePicker: state.showDatePicker,
  vehicleTypes: vehicleTypesUtils(state),
  showQuickChoices: state.showQuickChoices,
  serviceTypes: state.serviceTypes,
  autoSwitchLongHaul: state.autoSwitchLongHaul,
  outOfServiceStatus: state.outOfServiceStatus,
  currentCustomer: state.currentCustomer,
})

function mapDispatchToProps(dispatch) {
  return {
    timeTypeActions: bindActionCreators(timeTypeActionCreators, dispatch),
    pickupTimeActions: bindActionCreators(pickupTimeActionsCreator, dispatch),
    bookingActions: bindActionCreators(bookingActionCreators, dispatch),
    quickChoiceActions: bindActionCreators(quickChoiceActionsCreator, dispatch),
    vehicleTypeActions: bindActionCreators(vehicleTypeActionCreators, dispatch),
    serviceTypeActions: bindActionCreators(serviceTypeActionCreators, dispatch),
    outOfServiceStatusActions: bindActionCreators(outOfServiceStatusActionsCreator, dispatch),
    currentPopupIDActions: bindActionCreators(currentPopupIDActionsCreator, dispatch),
    infoAssignDriverPopupActions: bindActionCreators(infoAssignDriverPopupActionsCreator, dispatch),
    stepActions: bindActionCreators(stepActionCreators, dispatch),
    prevTimeTypeActions: bindActionCreators(prevTimeTypeActionCreators, dispatch),
    autoSwitchLongHaulActions: bindActionCreators(autoSwitchLongHaulActionsCreator, dispatch),
    datePickerActions: bindActionCreators(showDatePickerActionsCreator, dispatch),
    timeTypeUIActions: bindActionCreators(timeTypeUIActionsCreator, dispatch),
    showQuickChoiceActions: bindActionCreators(showQuickChoiceActionsCreator, dispatch),
    calendarBookingActions: bindActionCreators(calendarBookingActionCreators, dispatch)
  }
}


const closePopup = () => {
  LocationPopover.closeClass($('.Info.Popover'), 'visible')
}

class PickupTime extends React.Component {
  static removeItemLocalStorage() {
    window.localStorage.removeItem(ID_NEW_VEHICLE_TYPE)
    window.localStorage.removeItem(ID_NEW_SERVICE_TYPE)
  }

  constructor(props) {
    super(props)
    this.state = {
      showDatePicker: props.showDatePicker,
      minMinutes: 0,
      quickChoiceID: props.quickChoiceID,
      quickChoiceName: '',
      quickChoiceTimeType: '',
      timeType: props.timeTypeUI || props.timeType,
      isChooseNow: false,
      showQuickChoices: props.showQuickChoices,
      quickChoiceIdTemp: 0,
      quickChoiceTimeTypeTemp: '',
      isShowFavoriteDriverPopup: false,
    }

    this.closePicker = this.closePicker.bind(this)
    this.onChangeQuickChoice = this.onChangeQuickChoice.bind(this)
    this.onChangeQCWhenChangeVT = this.onChangeQCWhenChangeVT.bind(this)
    this.timeOutClosePopover = null
    this.escFunction = this.escFunction.bind(this)
  }

  componentDidMount() {
    const {
      bookAgainDetails,
      isShopping,
      timeType,
      prevTimeTypeActions,
      timeTypeUIActions,
      extraInfos
    } = this.props
    const favDriverId = window.localStorage.getItem(FAV_DRIVER_ID)
    if (!_.isEmpty(favDriverId)) {
      this.setState({
        isShowFavoriteDriverPopup: true,
      })
    }

    if (isMarketingPage && timeType === LONG_HAUL && !extraInfos.show_long_haul_time_type) {
      timeTypeUIActions.changeTimeTypeUI(SCHEDULE)
    } else if (!_.isEmpty(bookAgainDetails)) {
      this.checkBookingAgain()
    }
    
    this.initHoverPickupTime()
    this.initHoverDescriptionTimeTypePopup()
    window.addEventListener('beforeunload', PickupTime.removeItemLocalStorage)

    if (isShopping) {
      this.onChangePickupTime(NOW)
    }
    const timeTypeTemp = bookAgainDetails ? bookAgainDetails.time_type : timeType
    prevTimeTypeActions.changePrevTimeType(timeTypeTemp)
    document.addEventListener('keydown', this.escFunction, false)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false)
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      const {
        showQuickChoices
      } = this.state
      if (showQuickChoices) {
        this.closeQuickChoicePopup()
      } else {
        this.closePicker(true)
      }
    }
  }

  componentWillReceiveProps(newProps) {
    const {
      timeType,
      quickChoiceID,
      showQuickChoices,
      outOfServiceStatus,
      outOfServiceStatusActions,
      isShopping,
      timeTypeActions,
      pickupTimeActions,
      isShowChangeTimeTypeClick,
      setIsShowChangeTimeTypeClick,
    } = this.props
    const { isShowChangeTimeTypeClick: nextIsShowChangeTimeTypeClick } = newProps
    if (_.isEmpty(newProps.timeType) && !_.isEmpty(timeType)) {
      this.setState({ timeType: '' })
    }
    this.handleAutoSwitchLH(newProps)
    this.handleQuickChoiceId(newProps, quickChoiceID, showQuickChoices)
    if (!_.isEmpty(outOfServiceStatus) && outOfServiceStatus.change_longhaul_to_schedule === true) {
      this.setState({ timeType: SCHEDULE })
      outOfServiceStatusActions.updateOutOfServiceStatus({})
    }
    if (newProps.isShopping !== isShopping) {
      if (newProps.isShopping) {
        this.onChangePickupTime(NOW)
      }
      if (isShopping) {
        timeTypeActions.actionChangeTimeType('')
        pickupTimeActions.changePickupTime('')
      }
    }
    if (isShowChangeTimeTypeClick !== nextIsShowChangeTimeTypeClick && nextIsShowChangeTimeTypeClick) {
      this.onChangePickupTime(SCHEDULE)
      setIsShowChangeTimeTypeClick(false)
    }
  }

  componentDidUpdate(prevProps) {
    const {
      currentVehicleType,
      currentServiceType,
      timeType,
      pickupTime,
      timeTypeActions,
    } = this.props

    const {
      quickChoiceID,
      timeType: selectedTimeType
    } = this.state
    if (
      prevProps.currentServiceType === undefined || currentServiceType?.id !== prevProps.currentServiceType?.id
      || prevProps.currentVehicleType === undefined || currentVehicleType?.id !== prevProps.currentVehicleType?.id
    ) {
      if (quickChoiceID?.quickChoiceID > 0) {
        this.setState({ timeType })
      }

      if (this.checkUpdateTimeType()) {
        if (pickupTime !== '') {
          // update selected time & time type
          this.setMinimumMinutes(selectedTimeType, true)
          timeTypeActions.actionChangeTimeType(selectedTimeType)
        }
      }
    }

    if (timeType !== QUICK_CHOICE) {
      $('.PickupTime').removeClass('error')
    }
  }

  handleAutoSwitchLH(newProps) {
    const {
      timeType,
      showDatePicker,
      currentVehicleType,
      extraInfos,
      autoSwitchLongHaul,
    } = this.props

    const { showDatePicker: showDatePickerState } = this.state
    if (newProps.autoSwitchLongHaul === true && autoSwitchLongHaul !== newProps.autoSwitchLongHaul) {
      this.setState({ timeType: LONG_HAUL }, () => {
        this.setMinimumMinutes(LONG_HAUL, true)
        if (newProps.showDatePicker === true && showDatePickerState === false) {
          this.onChangePickupTime(LONG_HAUL)
        }
        if (extraInfos.show_long_haul_time_type) {
          this.resetQuickChoices(false)
        }
      })
    } else {
      if (!_.isUndefined(currentVehicleType) && timeType) {
        this.setMinimumMinutes(newProps.timeType)
      }
      if (newProps.showDatePicker !== showDatePicker) {
        this.setState({ showDatePicker: newProps.showDatePicker })
      }
    }
  }

  handleQuickChoiceId(newProps, quickChoiceID, showQuickChoices) {
    if (newProps.quickChoiceID !== quickChoiceID) {
      if (newProps.quickChoiceID === 0) {
        this.setState({
          quickChoiceID: 0,
          quickChoiceName: '',
          quickChoiceTimeType: ''
        })
      } else {
        const quickChoice = PickupTime.getQuickChoiceFromID(
          newProps.quickChoiceID,
          newProps.currentVehicleType,
          newProps.currentServiceType
        )
        if (!_.isEmpty(quickChoice)) {
          this.setState({
            showQuickChoices: false,
            quickChoiceID: quickChoice.id,
            quickChoiceName: quickChoice.name_on_webapp,
            quickChoiceTimeType: quickChoice.time_type,
            timeType: quickChoice.time_type
          })
        }
      }
    }
    if (newProps.showQuickChoices !== showQuickChoices) {
      this.setState({ showQuickChoices: newProps.showQuickChoices })
    }
  }

  hasQuickChoice() {
    const {
      currentVehicleType
    } = this.props

    return !!(currentVehicleType && currentVehicleType.quick_choices.length > 0)
  }

  isActivePickupTime(timeTypeTemp) {
    // timeType !== LONG_HAUL using in feature auto switch long haul when select schedule of Quick timeType #s72-6675
    const {
      quickChoiceID,
      isChooseNow,
      pickupTime,
    } = this.state
    const { timeTypeUI, extraInfos } = this.props
    if (this.hasQuickChoice() && quickChoiceID > 0
      && (extraInfos.show_long_haul_time_type || timeTypeUI !== LONG_HAUL)
    ) {
      return false
    }
    if (timeTypeTemp === NOW) {
      return timeTypeTemp === timeTypeUI && isChooseNow
    }
    return timeTypeTemp === timeTypeUI && _.isEmpty(pickupTime)
  }

  checkBookingAgain() {
    const {
      bookAgainDetails,
      timeTypeActions,
      timeTypeUIActions,
      currentVehicleType,
    } = this.props
    const timeType = bookAgainDetails.time_type
    let timeTypeUI = bookAgainDetails.time_type
    if (timeTypeUI === LONG_HAUL && !currentVehicleType?.settings?.show_long_haul_time_type) {
      timeTypeUI = bookAgainDetails.display_time_type === LONG_HAUL ? SCHEDULE
        : bookAgainDetails.display_time_type || bookAgainDetails.time_type
    }
    const actualQuickChoiceId = bookAgainDetails.quick_choice_id || bookAgainDetails.display_quick_choice_id
    const isValidTimeType = verifyTimeType(currentVehicleType, timeType, actualQuickChoiceId)
    if (isValidTimeType) {
      if (actualQuickChoiceId === 0 || actualQuickChoiceId === null) {
        this.setState({ timeType: timeTypeUI }, () => {
          timeTypeUIActions.changeTimeTypeUI(timeTypeUI)
          timeTypeActions.actionChangeTimeType(timeType)
        })
      } else {
        this.setQuickChoice()
      }
    }
  }

  checkBookingFromMarketing() {
    const {
      bookAgainDetails,
      timeTypeUIActions,
      extraInfos
    } = this.props
    const timeType = bookAgainDetails.time_type

    if(timeType === LONG_HAUL && !extraInfos.show_long_haul_time_type) {
      timeTypeUIActions.changeTimeTypeUI(SCHEDULE)
    }
  }

  onChangePickupTime(timeTypeTemp) {
    const {
      assignedDriver,
      timeType,
      currentPopupIDActions,
      infoAssignDriverPopupActions,
      prevTimeTypeActions
    } = this.props
    if (assignedDriver && timeType !== '' && timeType !== timeTypeTemp) {
      Utils.updateInfoAssignDriverPopupActions(
        {
          func: this.onChangePickupTimeAfterCheckBookingDetails.bind(this, timeTypeTemp),
        },
        currentPopupIDActions,
        infoAssignDriverPopupActions
      )
    } else {
      this.onChangePickupTimeAfterCheckBookingDetails(timeTypeTemp)
    }
    // save time type temp
    prevTimeTypeActions.changePrevTimeType(timeType)
  }

  handleChangeNow(timeTypeTemp) {
    const { timeTypeActions, pickupTimeActions, timeTypeUIActions } = this.props
    this.setState({
      timeType: timeTypeTemp,
      isChooseNow: true
    }, () => {
      timeTypeActions.actionChangeTimeType(timeTypeTemp)
      timeTypeUIActions.changeTimeTypeUI(timeTypeTemp)
      pickupTimeActions.changePickupTime('')
      CommonUtils.handleTrackTimeTypeMoengage(NOW)
    })
  }

  handleChangeFullDay() {
    // clear highlight on drop-off
    const locationElems = $('.Locations.Locations-Customs').find('.block-item .Input')
    const locationErrorElems = $('.Locations.Locations-Customs').find('.locations-error')
    locationElems.each((index) => {
      if (index > 0) {
        $(locationElems[index]).removeClass('error')
      }
    })
    locationErrorElems.each((index) => {
      if (index > 0) {
        $(locationErrorElems[index]).html('')
      }
    })
  }

  onChangePickupTimeAfterCheckBookingDetails(timeTypeTemp) {
    const {
      timeTypeActions, timeType, extraInfos, prevTimeTypeActions, timeTypeUIActions
    } = this.props
    if (timeTypeTemp === QUICK_CHOICE) {
      this.openQuickChoicePopup()
    } else if (timeTypeTemp === NOW) {
      this.handleChangeNow(timeTypeTemp)
    } else {
      if (timeTypeTemp === FULL_DAY) {
        this.handleChangeFullDay()
      }
      if (timeTypeTemp !== LONG_HAUL || extraInfos.show_long_haul_time_type) {
        timeTypeUIActions.changeTimeTypeUI(timeTypeTemp)
      }
      timeTypeActions.actionChangeTimeType(timeTypeTemp)
      this.setMinimumMinutes(timeTypeTemp)
      this.setState({ timeType: timeTypeTemp }, () => {
        if (this.verifyPickupTimeWhenChangeTimeType() === false || timeType === timeTypeTemp) {
          setTimeout(() => {
            this.openPicker(timeTypeTemp)
          }, 0)
        } else {
          // Track new timetype when pickup time not changed but timetype changed
          CommonUtils.handleTrackTimeTypeMoengage(timeTypeTemp)
          prevTimeTypeActions.changePrevTimeType(timeTypeTemp)
        }
      })
    }
  }

  verifyPickupTimeWhenChangeTimeType() {
    const { pickupTime, timeTypeActions, quickChoiceID } = this.props
    if (quickChoiceID > 0) {
      return false
    }
    const { minMinutes, timeType } = this.state
    const pickupTimeTemp = new Date(pickupTime)
    const now = new Date()
    const comparingTime = pickupTimeTemp - now
    if (comparingTime > (minMinutes * ONE_MIN_TO_MS)) {
      timeTypeActions.actionChangeTimeType(timeType)
      return true
    }
    return false
  }

  setQuickChoice() {
    const {
      timeTypeActions,
      bookAgainDetails,
      currentVehicleType,
      quickChoiceActions,
      pickupTimeActions,
      timeTypeUIActions
    } = this.props
    const bookingQuickChoiceId = bookAgainDetails.quick_choice_id || bookAgainDetails.display_quick_choice_id
    const isSetTimeType = bookingQuickChoiceId && bookAgainDetails.time_type !== LONG_HAUL
    const quickChoice = _.find(currentVehicleType?.quick_choices, {
      id: bookingQuickChoiceId
    })
    if (!_.isEmpty(quickChoice)) {
      this.setState({
        quickChoiceID: quickChoice.id,
        quickChoiceName: quickChoice.name_on_webapp,
        quickChoiceTimeType: quickChoice.time_type,
        timeType: quickChoice.time_type
      })
      timeTypeUIActions.changeTimeTypeUI(quickChoice.time_type)
      if (isSetTimeType) timeTypeActions.actionChangeTimeType(quickChoice.time_type)
      quickChoiceActions.changeQuickChoice(bookingQuickChoiceId)
      pickupTimeActions.changePickupTime(moment(moment().add(quickChoice.schedule_time, 'minutes')).format())
    }
  }

  setMinimumMinutes(timeType, updatePickupTime = false) {
    const {
      currentVehicleType,
      vehicleTypes,
      serviceTypes,
      pickupTime,
      pickupTimeActions
    } = this.props
    let vehicleTypeTemp = currentVehicleType
    const newVehicleTypeId = window.localStorage.getItem(ID_NEW_VEHICLE_TYPE)
    const newServiceTypeId = window.localStorage.getItem(ID_NEW_SERVICE_TYPE)
    if (newVehicleTypeId !== null) {
      vehicleTypeTemp = getVehicleType(vehicleTypes, newVehicleTypeId)
    }
    if (newServiceTypeId !== null) {
      vehicleTypeTemp = getServiceType(serviceTypes, newServiceTypeId).vehicle_types[0]
    }
    const minutes = minuMumBufferMinutes(vehicleTypeTemp, timeType, 0, true)
    this.setState({ minMinutes: minutes }, () => {
      if (updatePickupTime) {
        const minDateTime = moment(moment().add(minutes, 'minutes'))
        if (moment(pickupTime).isBefore(minDateTime)) {
          pickupTimeActions.changePickupTime(minDateTime.format())
        }
      }
    })
  }

  openQuickChoicePopup() {
    this.setState({ showQuickChoices: true })
  }

  closeQuickChoicePopup() {
    const {
      timeType,
      quickChoiceID
    } = this.state
    const {
      timeTypeActions
    } = this.props
    this.setState({ showQuickChoices: false })
    if (timeType === NOW && quickChoiceID === 0) {
      timeTypeActions.actionChangeTimeType(QUICK_CHOICE)
    } else {
      timeTypeActions.actionChangeTimeType(timeType)
    }
  }

  closeQCPopupWhenChangeVT(cancel) {
    const {
      timeType,
      quickChoiceID,
      quickChoiceTimeTypeTemp,
      quickChoiceIdTemp,
    } = this.state
    const {
      timeTypeActions,
      pickupTimeActions,
      quickChoiceActions,
      showQuickChoiceActions,
      vehicleTypes,
      serviceTypes
    } = this.props
    this.setState({
      showQuickChoices: false
    })
    if (cancel) {
      if (timeType === NOW && quickChoiceID === 0) {
        timeTypeActions.actionChangeTimeType(QUICK_CHOICE)
      } else {
        timeTypeActions.actionChangeTimeType(timeType)
      }
    } else if (quickChoiceIdTemp > 0 && !_.isEmpty(quickChoiceTimeTypeTemp)) {
      this.changeVehicleType()
      this.changeServiceType()

      const newVehicleTypeId = window.localStorage.getItem(ID_NEW_VEHICLE_TYPE)
      const newServiceTypeId = window.localStorage.getItem(ID_NEW_SERVICE_TYPE)
      let newVehicleType
      if (newVehicleTypeId !== null) {
        newVehicleType = getVehicleType(vehicleTypes, newVehicleTypeId)
      }
      if (newServiceTypeId !== null) {
        const newServiceType = getServiceType(serviceTypes, newServiceTypeId)
        newVehicleType = newServiceType.vehicle_types[0]
      }
      const quickChoice = _.find(newVehicleType.quick_choices, { id: quickChoiceIdTemp })
      quickChoiceActions.changeQuickChoice(quickChoiceIdTemp)
      timeTypeActions.actionChangeTimeType(quickChoiceTimeTypeTemp)
      pickupTimeActions.changePickupTime(moment(moment().add(quickChoice.schedule_time, 'minutes')).format())
      CommonUtils.handleTrackTimeTypeMoengage(QUICK_CHOICE, {
        id: quickChoiceIdTemp,
        schedule_time: quickChoice.schedule_time,
        time_type: quickChoiceTimeTypeTemp,
      })
    }
    this.setState({
      quickChoiceIdTemp: 0,
      quickChoiceTimeTypeTemp: '',
      showQuickChoices: false,
    })
    showQuickChoiceActions.changeShowQuickChoices(false)
    PickupTime.removeItemLocalStorage()
  }

  handleCloseFavDriverPopup() {
    this.setState({
      isShowFavoriteDriverPopup: false,
    })
    window.localStorage.setItem(FAV_DRIVER_ID, '')
  }

  handleGoToPreferenceDrivers() {
    const { stepActions } = this.props
    stepActions.loading()
    this.handleCloseFavDriverPopup()
    const url = `${window.location.origin}/preference_drivers/`
    window.location.replace(url)
  }

  openPicker(timeType) {
    const { autoSwitchLongHaul, autoSwitchGoStep2, datePickerActions } = this.props
    if (autoSwitchLongHaul === true && autoSwitchGoStep2 === true && timeType === LONG_HAUL) {
      this.setState({ showDatePicker: false })
      datePickerActions.showDatePicker(false)
    } else {
      this.setState({ showDatePicker: true })
      datePickerActions.showDatePicker(true)
    }
  }

  closePicker(cancel, selectedDateTime) {
    const {
      assignedDriver,
      pickupTime,
      currentPopupIDActions,
      infoAssignDriverPopupActions,
      prevTimeTypeActions,
      calendarBookingActions,
      extendedList,
    } = this.props
    const { showDatePicker } = this.state
    if (showDatePicker) {
      if (assignedDriver && !cancel && !_.isEmpty(pickupTime) && pickupTime !== selectedDateTime) {
        Utils.updateInfoAssignDriverPopupActions(
          {
            func: this.closePickerAfterCheckBookingDetails.bind(this, cancel, selectedDateTime)
          },
          currentPopupIDActions,
          infoAssignDriverPopupActions
        )
        this.setState({ showDatePicker: false })
      } else {
        this.closePickerAfterCheckBookingDetails(cancel, selectedDateTime)
      }
      if (cancel && _.isEmpty(extendedList)) {
        prevTimeTypeActions.resetTimeType()
      }
      calendarBookingActions.remove(`${SHOW_DATE_PICKER_BY_BOOKING}-SINGLE`)
    }
  }

  handleSetStateClosePicker(selectedTimeType, prevTimeTypeActions, selectedDateTime) {
    const { timeType, pickupTimeActions } = this.props
    this.setState({ showDatePicker: false }, () => {
      const {
        minMinutes
      } = this.state
      if (selectedDateTime) {
        pickupTimeActions.changePickupTime(selectedDateTime)
      } else {
        pickupTimeActions.changePickupTime(moment().add(minMinutes, 'minutes').format())
      }
      prevTimeTypeActions.changePrevTimeType(selectedTimeType)
      if ([SCHEDULE, FULL_DAY].includes(timeType)) {
        CommonUtils.handleTrackTimeTypeMoengage(timeType)
      }
    })
  }

  closePickerAfterCheckBookingDetails(cancel, selectedDateTime) {
    const {
      timeType,
      timeTypeUI,
      datePickerActions,
      autoSwitchLongHaulActions,
      autoSwitchLongHaul,
      quickChoiceID,
      currentVehicleType,
      currentServiceType,
      stepActions,
    } = this.props
    if (cancel) {
      this.setState({
        showDatePicker: false,
        timeType,
        quickChoiceID
      })

      const quickChoice = PickupTime.getQuickChoiceFromID(quickChoiceID, currentVehicleType, currentServiceType)
      if (!_.isEmpty(quickChoice)) {
        this.setState({
          quickChoiceName: quickChoice.name_on_webapp,
          quickChoiceTimeType: quickChoice.time_type,
        })
      }
      this.setMinimumMinutes(timeType)
    } else {
      const isLongHaulAndHideOnUI = autoSwitchLongHaul && timeTypeUI !== LONG_HAUL
      this.changeVehicleType()
      this.changeServiceType()
      if (isLongHaulAndHideOnUI) {
        stepActions.shouldGoToStep2(true)
      }
      this.resetQuickChoices()

      const { timeType: selectedTimeType, prevTimeTypeActions } = this.props || {}
      this.handleSetStateClosePicker(selectedTimeType, prevTimeTypeActions, selectedDateTime)
    }
    if (autoSwitchLongHaul === true) {
      autoSwitchLongHaulActions.autoSwitchLongHaul(false)
    }
    PickupTime.removeItemLocalStorage()
    datePickerActions.showDatePicker(false)
  }

  static getQuickChoiceFromID(quickChoiceID, vehicleType, serviceType) {
    let quickChoices
    if (!_.isUndefined(vehicleType)) {
      quickChoices = vehicleType.quick_choices
    } else {
      quickChoices = serviceType.vehicle_types[0].quick_choices
    }
    return getQuickChoice(quickChoices, quickChoiceID)
  }

  changeVehicleType() {
    const {
      vehicleTypeActions,
    } = this.props
    const newVehicleTypeId = window.localStorage.getItem(ID_NEW_VEHICLE_TYPE)
    if (newVehicleTypeId !== null) {
      vehicleTypeActions.changeVehicleType(_.parseInt(newVehicleTypeId))
    }
  }

  changeServiceType() {
    const {
      serviceTypeActions,
    } = this.props
    const newServiceTypeId = window.localStorage.getItem(ID_NEW_SERVICE_TYPE)
    if (newServiceTypeId !== null) {
      serviceTypeActions.changeServiceType(_.parseInt(newServiceTypeId))
    }
  }

  timeTypeClassName(timeType, className) {
    const {
      currentVehicleType,
    } = this.props
    let newClassName = className
    switch (timeType) {
      case NOW:
        if (!currentVehicleType?.settings?.immediate_enabled) {
          newClassName = `${className} hidden`
        }
        break
      case SCHEDULE:
        if (!currentVehicleType?.settings?.schedule_enabled) {
          newClassName = `${className} hidden`
        }
        break
      case FULL_DAY:
        if (!currentVehicleType?.settings?.full_day_enabled) {
          newClassName = `${className} hidden`
        }
        break
      case LONG_HAUL:
        if (!currentVehicleType?.settings?.long_haul_enabled) {
          newClassName = `${className} hidden`
        }
        break
      default:
        break
    }

    return newClassName
  }

  checkUpdateTimeType(newTimeType) {
    const {
      currentVehicleType
    } = this.props

    const {
      timeType: selectedTimeType
    } = this.state
    if (_.isUndefined(currentVehicleType)) {
      return false
    }
    let timeType = newTimeType
    if (_.isUndefined(timeType)) {
      timeType = selectedTimeType
    }

    const {
      currentServiceType,
    } = this.props
    switch (timeType) {
      case NOW:
        if (currentVehicleType?.settings?.immediate_enabled && !this.hasQuickChoice()) {
          return true
        }
        break
      case SCHEDULE:
        if (currentVehicleType?.settings?.schedule_enabled) {
          return true
        }
        break
      case FULL_DAY:
        if (_.isUndefined(currentServiceType)) {
          return false
        }

        if (currentVehicleType?.settings?.full_day_enabled && !currentServiceType.is_package) {
          return true
        }

        break
      case LONG_HAUL:
        if (currentVehicleType?.settings?.long_haul_enabled) {
          return true
        }

        break
      default:
        break
    }
    return false
  }

  resetQuickChoices(resetPropsQuickChoise = true) {
    const {
      quickChoiceActions,
      extraInfos,
      timeTypeUI,
    } = this.props
    if (timeTypeUI !== NOW || extraInfos.show_long_haul_time_type) {
      this.setState({
        quickChoiceID: 0,
        quickChoiceName: '',
        quickChoiceTimeType: ''
      })
      if (resetPropsQuickChoise) {
        quickChoiceActions.changeQuickChoice(0)
      }
    }
  }

  onChangeQuickChoice(quickChoice) {
    const {
      quickChoiceActions,
      timeTypeActions,
      pickupTimeActions,
      timeTypeUIActions
    } = this.props
    this.setState({
      showQuickChoices: false,
      quickChoiceID: quickChoice.id,
      quickChoiceName: quickChoice.name_on_webapp,
      quickChoiceTimeType: quickChoice.time_type,
      timeType: quickChoice.time_type
    }, () => {
      quickChoiceActions.changeQuickChoice(quickChoice.id)
      timeTypeActions.actionChangeTimeType(quickChoice.time_type)
      timeTypeUIActions.changeTimeTypeUI(quickChoice.time_type)
      pickupTimeActions.changePickupTime(moment(moment().add(quickChoice.schedule_time, 'minutes')).format())
      CommonUtils.handleTrackTimeTypeMoengage(QUICK_CHOICE, quickChoice)
    })
  }

  onChangeQCWhenChangeVT(quickChoice) {
    this.setState({
      quickChoiceIdTemp: quickChoice.id,
      quickChoiceTimeTypeTemp: quickChoice.time_type,
    })
  }

  renderQuickChoices(quickChoices, showQCWhenChangeVT) {
    const { bookAgainDetails } = this.props
    const { quickChoiceID } = this.state
    const quickChoicesTemp = [...quickChoices]
    if (isCustomerEditBooking() && bookAgainDetails.time_type !== 'now') {
      _.remove(quickChoicesTemp, qc => qc.time_type === 'now')
    }
    return quickChoicesTemp.map(quickChoice => (
      <li key={quickChoice.id} className={quickChoiceID === quickChoice.id ? 'qc-active' : ''}>
        <div className="Overlay-RadioBox h100 dlvr-overlay-radio-box">
          <span className={quickChoiceID === quickChoice.id ? 'qc-name-active' : 'qc-name-text'}>
            {quickChoice.name_on_webapp}
          </span>
          {showQCWhenChangeVT === false
            && <input type="radio" name="quick_choice" id={quickChoice.id} onChange={() => this.onChangeQuickChoice(quickChoice)} checked={quickChoice.id === quickChoiceID} />}
          {showQCWhenChangeVT
            && <input type="radio" name="quick_choice" id={quickChoice.id} onChange={() => this.onChangeQCWhenChangeVT(quickChoice)} />}
          <label className="White-text">
            <i className="b material-icons Icon">
              fiber_manual_record
            </i>
          </label>
        </div>
      </li>
    ))
  }

  getFullFormatTime() {
    const { pickupTime, extraInfos } = this.props
    const { minMinutes, quickChoiceID, quickChoiceName } = this.state
    if (this.hasQuickChoice() && quickChoiceID > 0) {
      return (
        <p className="center default-font reset m">
          {I18n.t('webapp.new_booking.step_1.pick_up_in', { time_text: quickChoiceName })}
        </p>
      )
    }
    if (pickupTime) {
      return (
        <p className="center default-font reset m">
          {I18n.t('batches.label.pickup')}
          &nbsp;
          {Utils.formatDateTime(pickupTime, extraInfos.country_code).time}
          {','}
          &nbsp;
          {Utils.formatDateTime(pickupTime, extraInfos.country_code).date}
        </p>
      )
    }
    return (
      <p className="center default-font reset m">
        {I18n.t('batches.label.pickup')}
        &nbsp;
        {Utils.formatDateTime(moment().add(minMinutes, 'minutes'), extraInfos.country_code).time}
        {','}
        &nbsp;
        {Utils.formatDateTime(moment().add(minMinutes, 'minutes'), extraInfos.country_code).date}
      </p>
    )
  }

  initHoverPickupTime() {
    const self = this
    let hideTimeoutID = null
    $(document).on({
      mouseenter(event) {
        let findValue = $(this).find('input').val()
        if (findValue === '' || findValue === QUICK_CHOICE) {
          findValue = NOW
        }
        hideTimeoutID = setTimeout(() => {
          self.showPopup(event, findValue)
        }, 500)
        if (self.timeOutClosePopover) {
          clearTimeout(self.timeOutClosePopover)
        }
      },
      mouseleave() {
        clearTimeout(hideTimeoutID)
        $('.BookingWizard .Content').scroll(() => {
          setTimeout(() => {
            LocationPopover.closeClass($('.PickupTime-DatePicker-Introduce'), 'visible')
          }, 250)
        })
        self.timeOutClosePopover = setTimeout(() => {
          LocationPopover.closeClass($('.PickupTime-DatePicker-Introduce'), 'visible')
        }, 500)
      },
    }, '.PickupTime .block-item')
  }

  initHoverDescriptionTimeTypePopup() {
    let hideTimeoutID = null
    const self = this
    $(document).on({
      mouseenter() {
        if (hideTimeoutID) {
          clearTimeout(hideTimeoutID)
        }
        if (self.timeOutClosePopover) {
          clearTimeout(self.timeOutClosePopover)
        }
      },
      mouseleave() {
        clearTimeout(hideTimeoutID)
        hideTimeoutID = setTimeout(() => {
          LocationPopover.closeClass($('.PickupTime-DatePicker-Introduce'), 'visible')
        }, 500)
      }
    }, '.Popover.PickupTime-DatePicker-Introduce')
  }

  /* eslint-disable class-methods-use-this */
  showPopup(event, id) {
    LocationPopover.closeClass($('.Info.Popover'), 'visible')

    const config = {
      type: 'no-select-input',
      popover: 30,
      top: 70,
      arrow: 74
    }
    const elementConfig = {
      element: $(`#PickupTime-DatePicker-Introduce-${id}`),
      arrow_element: $(`#PickupTime-DatePicker-Introduce-${id} .Popover-Arrow`),
      element_left_position: '100%',
      element_index_position: 12,
      arrow_element_position: 15
    }
    LocationPopover.init(
      document.getElementById(`PickupTime-DatePicker-Introduce-${id}-name`),
      $(`#PickupTime-DatePicker-Introduce-${id}`).height(),
      0,
      config,
      elementConfig
    )
  }

  // closePopup() {
  //   LocationPopover.closeClass($('.Info.Popover'), 'visible')
  // }

  renderExplanation() {
    const {
      currentServiceType
    } = this.props
    if (_.isUndefined(currentServiceType)) return null

    return currentServiceType.booking_button_list.map((timeType) => {
      switch (timeType) {
        case SCHEDULE:
          return this.renderExplanationByTimeType(SCHEDULE)
        case FULL_DAY:
          return this.renderExplanationByTimeType(FULL_DAY)
        case LONG_HAUL:
          return this.renderExplanationByTimeType(LONG_HAUL)
        default:
          return this.renderExplanationByTimeType(NOW)
      }
    })
  }

  renderExplanationByTimeType(timeType) {
    const {
      currentVehicleType,
    } = this.props

    const timeTypeExplanation = currentVehicleType.time_type_explanations
      .find(explanation => (explanation.time_type === timeType))

    return (
      !_.isUndefined(timeTypeExplanation) && (
        <div key={`explanation-${timeType}`} id={`PickupTime-DatePicker-Introduce-${timeType}`} className="Popover Info PickupTime-DatePicker-Introduce">
          <span className="BoxCustom-Close" onClick={closePopup}>
            <i className="b material-icons Icon">
              close
            </i>
          </span>
          <div className="Popover-Arrow" />
          <div className="PickupTime-Image">
            <img src={timeTypeExplanation.icon} alt="icon" />
            <b>
              {timeTypeExplanation.title}
            </b>
          </div>
          <div className="PickupTime-Explanation">
            {/* eslint-disable-next-line */}
            <p dangerouslySetInnerHTML={{ __html: timeTypeExplanation.formated_content }} />
          </div>
        </div>
      )
    )
  }

  checkEnableTimeType() {
    const { currentServiceType, currentVehicleType, extraInfos } = this.props
    const arr = []
    const handleCheckEnableTimeType = (timeType) => {
      if (
        (timeType === NOW && currentVehicleType?.settings?.immediate_enabled && !this.hasQuickChoice())
        || (timeType === NOW && this.hasQuickChoice())
        || (timeType === IMMEDIATE && currentVehicleType?.settings?.immediate_enabled && !this.hasQuickChoice())
        || (timeType === IMMEDIATE && this.hasQuickChoice())
        || (timeType === SCHEDULE && currentVehicleType?.settings?.schedule_enabled)
        || (timeType === FULL_DAY && currentVehicleType?.settings?.full_day_enabled && !currentServiceType.is_package)
        || (
          timeType === LONG_HAUL
          && currentVehicleType?.settings?.long_haul_enabled
          && extraInfos.show_long_haul_time_type
        )
      ) {
        arr.push(timeType)
      }
    }
    if (_.isUndefined(currentVehicleType)) return arr
    _.forEach(currentServiceType.booking_button_list, (timeType) => {
      handleCheckEnableTimeType(timeType)
    })
    return arr
  }

  renderTimeType() {
    // const { currentServiceType } = this.props
    return this.checkEnableTimeType().map((timeType, idx) => {
      const sizeTimeType = _.size(this.checkEnableTimeType()) <= 3
      const customBlockItem = idx !== 0 && idx % 2 === 0 && sizeTimeType ? 'flex-auto w100' : ''
      switch (timeType) {
        case SCHEDULE:
          return this.renderPickupTimeSchedule(customBlockItem)
        case FULL_DAY:
          return this.renderPickupTimeFullday(customBlockItem)
        case LONG_HAUL:
          return this.renderPickupTimeLongHaul(customBlockItem)
        default:
          return this.renderPickupTimeNow(customBlockItem)
      }
    })
  }

  renderQuickChoiceImmediate(customBlockItem) {
    const { extraInfos, timeTypeUI } = this.props
    const { quickChoiceID, quickChoiceTimeType } = this.state
    return (
      <div
        key="quick_choice"
        id={`PickupTime-DatePicker-Introduce-${NOW}-name`}
        className={`block-item ${quickChoiceID > 0 && (timeTypeUI !== LONG_HAUL || extraInfos.show_long_haul_time_type) ? 'selected' : ''} ${customBlockItem}`}
      >
        <input
          type="radio"
          name="booking[time_type]"
          value={quickChoiceTimeType}
          checked={quickChoiceID > 0}
          onClick={() => this.onChangePickupTime(QUICK_CHOICE)}
        />
        <div className="PickupTime-Image PickupTime-Image-Custom">
          <i className="xb material-icons Icon">
            access_alarm
          </i>
        </div>
        <div className="PickupTime-Name default-font">
          {I18n.t('quick')}
        </div>
      </div>
    )
  }

  renderPickupTimeNow(customBlockItem) {
    const {
      bookAgainDetails,
      currentVehicleType,
    } = this.props

    const { quickChoiceName } = this.state
    const setActiveClassNow = this.isActivePickupTime(NOW) ? this.timeTypeClassName(NOW, 'selected block-item') : this.timeTypeClassName(NOW, 'block-item')
    let isHideImmediate = false
    const hasQuickChoice = this.hasQuickChoice()
    if (isCustomerEditBooking() && bookAgainDetails.time_type !== 'now') {
      isHideImmediate = true
      if (hasQuickChoice) {
        const quickChoices = currentVehicleType.quick_choices
        let i = 0
        for (i; i < _.size(quickChoices); i += 1) {
          if (quickChoices[i].time_type !== 'now') {
            isHideImmediate = false
            break
          }
        }
      }
    }

    if (hasQuickChoice && !isHideImmediate) {
      return this.renderQuickChoiceImmediate(customBlockItem)
    }

    if (isHideImmediate) return ''

    return (
      <div
        key="now"
        id={`PickupTime-DatePicker-Introduce-${NOW}-name`}
        className={`${setActiveClassNow} ${customBlockItem}`}
      >
        <input
          type="radio"
          name="booking[time_type]"
          value={NOW}
          checked={this.isActivePickupTime(NOW)}
          onClick={() => this.onChangePickupTime(NOW)}
          readOnly
        />
        {quickChoiceName
          && (
            <div className="PickupTime-Explanation" />
          )
        }
        <div className="PickupTime-Image">
          <i className="xb material-icons Icon">
            access_alarm
          </i>
        </div>
        <div className="PickupTime-Name default-font">
          {I18n.t('webapp.new_booking.step_1.immediate_title')}
        </div>
      </div>
    )
  }

  handleClickPickupTimeSchedule = () => {
    this.onChangePickupTime(SCHEDULE)
  }

  renderPickupTimeSchedule(customBlockItem) {
    const { currentVehicleType } = this.props
    const setActiveClassSchedule = this.isActivePickupTime(SCHEDULE) ? this.timeTypeClassName(SCHEDULE, 'selected block-item') : this.timeTypeClassName(SCHEDULE, 'block-item')

    if (!currentVehicleType?.settings?.schedule_enabled) {
      return null
    }

    return (
      <div
        key="schedule"
        id={`PickupTime-DatePicker-Introduce-${SCHEDULE}-name`}
        className={`${setActiveClassSchedule} ${customBlockItem}`}
      >
        <input
          type="radio"
          name="booking[time_type]"
          value={SCHEDULE}
          checked={this.isActivePickupTime(SCHEDULE)}
          onClick={() => this.handleClickPickupTimeSchedule()}
          readOnly
        />
        <div className="PickupTime-Image">
          <i className="xb material-icons Icon">
            event
          </i>
        </div>
        <div className="PickupTime-Name default-font">
          {I18n.t('webapp.new_booking.step_1.scheduled_title')}
        </div>
      </div>
    )
  }

  renderPickupTimeFullday(customBlockItem) {
    const { currentVehicleType, currentServiceType } = this.props

    const setActiveClassFullday = this.isActivePickupTime(FULL_DAY) ? this.timeTypeClassName(FULL_DAY, 'selected block-item') : this.timeTypeClassName(FULL_DAY, 'block-item')
    if (currentServiceType.is_package || !currentVehicleType.settings.full_day_enabled) {
      return null
    }
    return (
      <div
        key="fullday"
        id={`PickupTime-DatePicker-Introduce-${FULL_DAY}-name`}
        className={`${setActiveClassFullday} ${customBlockItem}`}
      >
        <input
          type="radio"
          name="booking[time_type]"
          value={FULL_DAY}
          checked={this.isActivePickupTime(FULL_DAY)}
          onClick={() => this.onChangePickupTime(FULL_DAY)}
          readOnly
        />
        <div className="PickupTime-Image">
          <img src={this.isActivePickupTime(FULL_DAY) ? FULL_DAY_WHITE_ICON : FULL_DAY_DARK_GRAY_ICON} />
        </div>
        <div className="PickupTime-Name default-font">
          {I18n.t('webapp.new_booking.step_1.full_day_title')}
        </div>
        {currentVehicleType.settings.full_day_is_beta
          && (
            <div className="full-day-beta">
              BETA
            </div>
          )
        }
      </div>
    )
  }

  renderPickupTimeLongHaul(customBlockItem) {
    const { currentVehicleType, isEditWebMultipleBooking } = this.props

    if (isEditWebMultipleBooking) {
      return null
    }

    const setActiveClassLongHaul = this.isActivePickupTime(LONG_HAUL) ? this.timeTypeClassName(LONG_HAUL, 'selected block-item') : this.timeTypeClassName(LONG_HAUL, 'block-item')
    if (!currentVehicleType.settings.long_haul_enabled) {
      return null
    }
    return (
      <div
        key="longhaul"
        id={`PickupTime-DatePicker-Introduce-${LONG_HAUL}-name`}
        className={`${setActiveClassLongHaul} ${customBlockItem}`}
      >
        <input
          type="radio"
          name="booking[time_type]"
          value={LONG_HAUL}
          checked={this.isActivePickupTime(LONG_HAUL)}
          onClick={() => this.onChangePickupTime(LONG_HAUL)}
        />
        <div className="PickupTime-Image">
          <img src={this.isActivePickupTime(LONG_HAUL) ? LONG_HAUL_WHITE_ICON : LONG_HAUL_GRAY_ICON} />
        </div>
        <div className="PickupTime-Name default-font">
          {I18n.t('webapp.new_booking.step_1.long_haul_title')}
        </div>
        {currentVehicleType.settings.long_haul_is_beta
          && (
            <div className="full-day-beta">
              BETA
            </div>
          )
        }
      </div>
    )
  }

  renderQuickChoicePopup() {
    const {
      currentVehicleType,
      vehicleTypes,
      serviceTypes,
    } = this.props
    const {
      quickChoiceIdTemp,
    } = this.state
    let quickChoices = currentVehicleType.quick_choices
    let showQCWhenChangeVT = false
    const newVehicleTypeId = window.localStorage.getItem(ID_NEW_VEHICLE_TYPE)
    const newServiceTypeId = window.localStorage.getItem(ID_NEW_SERVICE_TYPE)
    if (newVehicleTypeId !== null) {
      const newVehicleType = getVehicleType(vehicleTypes, newVehicleTypeId)
      if (!_.isEmpty(newVehicleType)) {
        quickChoices = newVehicleType.quick_choices
        showQCWhenChangeVT = true
      }
    }
    if (newServiceTypeId !== null) {
      const newServiceType = getServiceType(serviceTypes, newServiceTypeId)
      const newVehicleType = newServiceType.vehicle_types[0]
      if (!_.isEmpty(newVehicleType)) {
        quickChoices = newVehicleType.quick_choices
        showQCWhenChangeVT = true
      }
    }

    let classButtonOk = ''
    if (quickChoiceIdTemp > 0) {
      classButtonOk = 'White no-hover cur-pointer'
    } else {
      classButtonOk = 'disabled Gray no-hover cur-pointer'
    }
    return (
      <div className="visible vertical-scroll Modal fixed Booking-Modal">
        <div className="z-index-2 dlvr-normal-booking">
          <div className="Box">
            <div className="Box-Content">
              <div className="Normal">
                <h5 className="dlvr-booking-title">
                  {I18n.t('webapp.new_booking.step_1.quick_choice')}
                </h5>
                <ul className="quick-choices-ul">
                  {this.renderQuickChoices(quickChoices, showQCWhenChangeVT)}
                </ul>
              </div>
            </div>
          </div>
          {showQCWhenChangeVT
            && (
              <div className="Normal-Booking-Actions Custom">
                <a className="Gray no-hover cur-pointer" onClick={() => this.closeQCPopupWhenChangeVT(true)}>
                  {I18n.t('webapp.new_booking.back')}
                </a>
                <a className={classButtonOk} onClick={() => this.closeQCPopupWhenChangeVT(false)}>
                  {I18n.t('webapp.action.ok')}
                </a>
              </div>
            )
          }
        </div>
        {showQCWhenChangeVT === false && <span className="close-modal-common" onClick={() => this.closeQuickChoicePopup()} />}
      </div>
    )
  }

  renderFavoriteDriverPopup() {
    return (
      <div className="dlvr-visible vertical-scroll Modal fixed Booking-Modal">
        <div className="z-index-2 Normal-Booking">
          <div className="Box">
            <div className="Box-Icon Box-Icon-Popup">
              <div className="Logo">
                <img src={ICON_FAV_DRIVER_ADDED} className="m-h32px" />
              </div>
            </div>
            <div className="Box-Content">
              <div className="Normal">
                <h5 className="Normal-Booking-Title">
                  {I18n.t('webapp.new_booking.step_1.favorite_driver_added')}
                </h5>
                <div>
                  <p className="center pre-line">
                    {I18n.t('webapp.new_booking.step_1.manage_your_favorite')}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="Normal-Booking-Actions Custom">
            <a className="Gray no-hover cur-pointer" onClick={() => this.handleCloseFavDriverPopup(false)}>
              {I18n.t('webapp.new_booking.back')}
            </a>
            <a className="White no-hover cur-pointer" onClick={() => this.handleGoToPreferenceDrivers()}>
              {I18n.t('webapp.new_booking.manage')}
            </a>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const {
      showDatePicker,
      showQuickChoices,
      minMinutes,
      isShowFavoriteDriverPopup,
    } = this.state
    const {
      currentVehicleType,
      pickupTime,
      extraInfos,
      timeType,
      quickChoiceID,
      isShopping,
    } = this.props
    const countryCode = extraInfos.country_code
    const brandIco = countryCode === 'PH' ? 'transportify' : 'deliveree'
    const setPickupforTimetype = pickupTime ? 'PickupTime-Show-Pickup' : ''
    const checkTimeTypeQuickChoice = this.hasQuickChoice() && quickChoiceID > 0
    if (_.isEmpty(currentVehicleType)) {
      return <span />
    }
    return (
      <div>
        <div className={`PickupTime PickupTime-List flex-wrap PickupTime-Custom ${setPickupforTimetype} reset mb block`}>
          {this.renderTimeType()}
          {this.renderExplanation()}
          {showDatePicker
            && (
              <BookingCalendar
                isShowOkBtn
                isShowCancelBtn
                brandIcon={brandIco}
                selectedDateTime={pickupTime}
                minMinutes={minMinutes}
                closePicker={this.closePicker}
                maximumPickupDays={extraInfos.maximum_pickup_time_days}
                modalCssClassName="PickupTime-DatePicker-Custom"
                countryCode={countryCode}
              />
            )
          }
          {showQuickChoices && this.renderQuickChoicePopup()}
          {isShowFavoriteDriverPopup && this.renderFavoriteDriverPopup()}
          <input
            type="hidden"
            name="booking[pickup_time]"
            value={pickupTime}
          />
        </div>
        {isShopping
          ? (!_.isEmpty(timeType) || pickupTime)
          && (
            <div className="PickupTime-Show Dark-Green-bg White-text cur-pointer">
              <p className="center default-font reset m">
                {I18n.t('webapp.shopping.shop_now')}
              </p>
            </div>
          )
          : !_.isEmpty(timeType) && (pickupTime || (_.isEmpty(pickupTime) && quickChoiceID > 0))
          && (
            <div
              className="PickupTime-Show Dark-Green-bg White-text cur-pointer"
              onClick={() => this.onChangePickupTime(checkTimeTypeQuickChoice ? QUICK_CHOICE : timeType)}
            >
              {this.getFullFormatTime()}
            </div>
          )
        }
      </div>
    )
  }
}

PickupTime.propTypes = {
  assignedDriver: PropTypes.shape({}),
  currentVehicleType: PropTypes.shape({}),
  currentServiceType: PropTypes.shape({}).isRequired,
  extraInfos: PropTypes.shape({}).isRequired,
  bookAgainDetails: PropTypes.shape({}),
  pickupTime: PropTypes.string.isRequired,
  showDatePicker: PropTypes.bool.isRequired,
  quickChoiceID: PropTypes.number.isRequired,
  vehicleTypeActions: PropTypes.shape({}).isRequired,
  vehicleTypes: PropTypes.instanceOf(Array).isRequired,
  showQuickChoices: PropTypes.bool.isRequired,
  serviceTypeActions: PropTypes.shape({}).isRequired,
  calendarBookingActions: PropTypes.shape({}).isRequired,
  serviceTypes: PropTypes.instanceOf(Array).isRequired,
  outOfServiceStatus: PropTypes.shape({}).isRequired,
  outOfServiceStatusActions: PropTypes.shape({}).isRequired,
  currentPopupIDActions: PropTypes.shape({}).isRequired,
  infoAssignDriverPopupActions: PropTypes.shape({}).isRequired,
  quickChoiceActions: PropTypes.shape({}).isRequired,
  prevTimeTypeActions: PropTypes.shape({}).isRequired,
  isShopping: PropTypes.bool,
  timeType: PropTypes.string,
  timeTypeUI: PropTypes.string,
  timeTypeActions: PropTypes.shape({}).isRequired,
  stepActions: PropTypes.shape({}).isRequired,
  autoSwitchLongHaul: PropTypes.bool,
  isEditWebMultipleBooking: PropTypes.bool,
  isShowChangeTimeTypeClick: PropTypes.bool,
  setIsShowChangeTimeTypeClick: PropTypes.func,
  extendedList: PropTypes.instanceOf(Array),
}

PickupTime.defaultProps = {
  assignedDriver: undefined,
  currentVehicleType: undefined,
  bookAgainDetails: undefined,
  isShopping: false,
  timeType: undefined,
  timeTypeUI: undefined,
  autoSwitchLongHaul: false,
  isEditWebMultipleBooking: false,
  isShowChangeTimeTypeClick: false,
  setIsShowChangeTimeTypeClick: () => undefined,
  extendedList: [],
}

export default connect(mapStateToProps, mapDispatchToProps)(PickupTime)

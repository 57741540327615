import { lazy } from 'react'
import { RouteProps } from 'react-router-dom'
import PATHS from './paths'
import SingleBookingView from 'view/SingleBookingView'
import AccountManagementView from 'view/AccountManagementView'
import { SIGN_IN_MODAL, SIGN_UP_MODAL } from 'components/new_booking/guest_flow/constants'
// VIEW
// const SingleBookingView = lazy(() => import('view/SingleBookingView'))
const MultipleBookingView = lazy(() => import('view/MultipleBookingView'))
const EzSheetView = lazy(() => import('view/EzSheetView'))
const SmartPlannerView = lazy(() => import('view/SmartPlannerView'))
const SelectAreaView = lazy(() => import('view/SelectAreaView'))
const Locations = lazy(() => import('view/MyLocations/Locations'))
const LocationEdit = lazy(() => import('view/MyLocations/EditLocation'))
const NewLocation = lazy(() => import('view/MyLocations/NewLocation'))
const Employees = lazy(() => import('view/Employees/EmployeeList'))
const NewEmployee = lazy(() => import('view/Employees/NewEmployee'))
const Drivers = lazy(() => import('view/DriversListView/DriversListView'))
const MyBookingView = lazy(() => import('view/MyBookingView'))
const BookingDetail = lazy(() => import('view/BookingDetail/BookingDetail'))
const SelectBatchView = lazy(() => import('view/SelectBatchView'))
const TrackingBookingPage = lazy(() => import('view/LiveTracking/TrackingBookingPage'))
const ShareBookingPage = lazy(() => import('view/LiveTracking/ShareBookingPage'))
const FollowBookingPage = lazy(() => import('view/LiveTracking/FollowBookingPage'))
const PaymentNoneBP = lazy(() => import('view/PaymentNoneBp'))
const APIDashboardsView = lazy(() => import('view/ApiDashboardsView'))
const WalletView = lazy(() => import('view/WalletView'))
const TestMyBookings = lazy(() => import('view/ApiDashboardsView/TestMyBookings'))
const WalletTopup = lazy(() => import('view/WalletView/WalletTopup'))
const AnalyticsView = lazy(() => import('view/AnalyticsView'))
const DownloadAnalyticsLater = lazy(() => import('view/AnalyticsView/DownloadAnalyticsLater'))

const BatchManage = lazy(() => import('view/BatchManageView'))

interface RoutesProps {
  [key: string]: RouteProps & {
    isPrivate: boolean
  }
}

const routes: RoutesProps = {
  //Single Booking
  NEW_BOOKING: {
    path: PATHS.NEW_BOOKING,
    element: <SingleBookingView />,
    isPrivate: false,
    caseSensitive: false,
  },
  NEW_BOOKING_SINGLE: {
    path: PATHS.NEW_BOOKING_SINGLE,
    element: <SingleBookingView />,
    isPrivate: false,
    caseSensitive: false,
  },
  EDIT_BOOKING: {
    path: PATHS.EDIT_BOOKING,
    element: <SingleBookingView />,
    isPrivate: true,
    caseSensitive: false,
  },
  BOOK_AGAIN_BOOKING: {
    path: PATHS.BOOK_AGAIN_BOOKING,
    element: <SingleBookingView />,
    isPrivate: true,
    caseSensitive: false,
  },
  PAYMENT: {
    path: PATHS.PAYMENTS,
    element: <PaymentNoneBP />,
    isPrivate: true,
    caseSensitive: false,
  },
  // Multiple booking
  MULTIPLE_BOOKING: {
    path: PATHS.MULTIPLE_BOOKING,
    element: <MultipleBookingView />,
    isPrivate: true,
    caseSensitive: false,
  },
  EZ_SPREAD_BOOKING: {
    path: PATHS.EZ_SPREAD_BOOKING,
    element: <EzSheetView />,
    isPrivate: true,
    caseSensitive: false,
  },
  SMART_PLANNER_BOOKING: {
    path: PATHS.SMART_PLANNER_BOOKING,
    element: <SmartPlannerView />,
    isPrivate: true,
    caseSensitive: false,
  },
  SELECT_AREA: {
    path: PATHS.SELECT_AREA,
    element: <SelectAreaView />,
    isPrivate: false,
    caseSensitive: false,
  },
  // Batch booking
  BATCH_MANAGE: {
    path: PATHS.BATCH_MANAGE,
    element: <BatchManage />,
    isPrivate: true,
    caseSensitive: false,
  },
  BATCH_MANAGE_DETAIL: {
    path: PATHS.BATCH_MANAGE_DETAIL,
    element: <BatchManage />,
    isPrivate: true,
    caseSensitive: false,
  },

  // AUTHENTICATION
  SIGN_IN: {
    path: PATHS.SIGN_IN,
    element: <AccountManagementView view={SIGN_IN_MODAL} />,
    isPrivate: false,
    caseSensitive: false,
  },
  SIGN_UP: {
    path: PATHS.SIGN_UP,
    element: <AccountManagementView view={SIGN_UP_MODAL} />,
    isPrivate: false,
    caseSensitive: false,
  },
  RESET_PASSWORD: {
    path: PATHS.RESET_PASSWORD,
    element: <div>reset-password</div>,
    isPrivate: false,
    caseSensitive: false,
  },
  CHANGE_PASSWORD: {
    path: PATHS.CHANGE_PASSWORD,
    element: <div>change-password</div>,
    isPrivate: false,
    caseSensitive: false,
  },
  MERGE_ACCOUNT: {
    path: PATHS.MERGE_ACCOUNT,
    element: <div>merge-account</div>,
    isPrivate: false,
    caseSensitive: false,
  },
  // my booking
  BOOKINGS: {
    path: PATHS.BOOKINGS,
    element: <MyBookingView />,
    isPrivate: true,
    caseSensitive: false,
  },

  // booking detail
  BOOKING_DETAIL: {
    path: PATHS.BOOKING_DETAIL,
    element: <BookingDetail />,
    isPrivate: true,
    caseSensitive: false,
  },

  // select batch
  SELECT_BATCH: {
    path: PATHS.SELECT_BATCH,
    element: <SelectBatchView />,
    isPrivate: true,
    caseSensitive: false,
  },

  //MORE
  LOCATIONS: {
    path: PATHS.LOCATIONS,
    element: <Locations />,
    isPrivate: true,
    caseSensitive: false,
  },
  ADD_LOCATIONS: {
    path: PATHS.ADD_LOCATIONS,
    element: <NewLocation />,
    isPrivate: true,
    caseSensitive: false,
  },
  LOCATION_DETAIL: {
    path: PATHS.LOCATION_DETAIL,
    element: <LocationEdit />,
    isPrivate: true,
    caseSensitive: false,
  },
  EMPLOYEES: {
    path: PATHS.EMPLOYEES,
    element: <Employees />,
    isPrivate: true,
    caseSensitive: false,
  },
  NEW_EMPLOYEES: {
    path: PATHS.NEW_EMPLOYEE,
    element: <NewEmployee />,
    isPrivate: true,
    caseSensitive: false,
  },
  DRIVERS: {
    path: PATHS.DRIVERS,
    element: <Drivers />,
    isPrivate: true,
    caseSensitive: false,
  },
  API_DASHBOARD: {
    path: PATHS.API_DASHBOARD,
    element: <APIDashboardsView />,
    isPrivate: true,
    caseSensitive: false,
  },
  TEST_MY_BOOKING: {
    path: PATHS.TEST_MY_BOOKING,
    element: <TestMyBookings />,
    isPrivate: true,
    caseSensitive: false,
  },
  WALLET: {
    path: PATHS.WALLET,
    element: <WalletView />,
    isPrivate: true,
    caseSensitive: false,
  },
  WALLET_BUSINESS: {
    path: PATHS.WALLET_BUSINESS,
    element: <WalletView />,
    isPrivate: true,
    caseSensitive: false,
  },
  WALLET_TOPUP: {
    path: PATHS.WALLET_TOPUP,
    element: <WalletTopup />,
    isPrivate: true,
    caseSensitive: false,
  },
  WALLET_BUSINESS_TOPUP: {
    path: PATHS.WALLET_BUSINESS_TOPUP,
    element: <WalletTopup />,
    isPrivate: true,
    caseSensitive: false,
  },
  ANALYTICS: {
    path: PATHS.ANALYTICS,
    element: <AnalyticsView />,
    isPrivate: false,
    caseSensitive: false,
  },
  DOWNLOAD_ANALYTICS_LATER: {
    path: PATHS.DOWNLOAD_ANALYTICS_LATER,
    element: <DownloadAnalyticsLater />,
    isPrivate: false,
    caseSensitive: false,
  },

  // Tracking
  TRACKING: {
    path: PATHS.TRACKING,
    element: <TrackingBookingPage />,
    isPrivate: true,
    caseSensitive: false,
  },
  SHARE: {
    path: PATHS.SHARE,
    element: <ShareBookingPage />,
    isPrivate: false,
    caseSensitive: false,
  },
  FOLLOW: {
    path: PATHS.FOLLOW,
    element: <FollowBookingPage />,
    isPrivate: false,
    caseSensitive: false,
  },
}

export default routes
